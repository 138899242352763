import { BlockHeightCountdown } from "@/components/auctionTable/BlockHeightCountdown";
import { Countdown } from "@/components/auctionTable/Countdown";
import { useCurrentAuctionQuery } from "@/queries/auction";
import { AuctionCycle } from "@/schemas/auction";
import { AnimatePresence, motion } from "framer-motion";
import { Outlet, useLocation, useNavigate, useParams } from '@remix-run/react';
import { useEffect } from 'react';
import { z } from 'zod';

export default function AuctionCyclePage() {
    const currentAuctionCycle = useCurrentAuctionQuery().data?.auction_cycle;
    const params = useParams();
    const location = useLocation();
    return (
        <AuctionCyclePageContent
            key={`auction-cycle-page-content`}
            auctionCycleCurrent={currentAuctionCycle}
            pathname={location.pathname}
            auctionCycleParam={params.auction_cycle}
        />
    )
}


const AuctionCyclePageContent = ({ auctionCycleCurrent, pathname, auctionCycleParam }: {
    auctionCycleCurrent: AuctionCycle | undefined,
    pathname: string,
    auctionCycleParam: string | undefined
}) => {
    const navigate = useNavigate();
    const auctionCycleParamParsed = z.coerce.number().int().nonnegative().safeParse(auctionCycleParam);

    useEffect(() => {
        if (!auctionCycleParamParsed.success) { navigate(`/auction/${auctionCycleCurrent}`) }
        const redirectPath = getAuctionRedirectPath(auctionCycleCurrent, pathname, auctionCycleParam);
        if (redirectPath) { navigate(redirectPath) }

    }, [auctionCycleCurrent, pathname, auctionCycleParam, navigate]);

    return (
        <motion.div className="flex flex-col justify-center items-center mx-auto">
            <AnimatePresence mode='wait'>
                {auctionCycleCurrent === undefined ? (null) : (
                    <Outlet key={`auction-outlet-${auctionCycleParam}-${auctionCycleCurrent}`} />
                )}

            </AnimatePresence>
        </motion.div>
    )
};

export const AuctionHeaderAndNav = ({ auctionCycleCurrent, auctionCycleParam }: {
    auctionCycleCurrent: AuctionCycle,
    auctionCycleParam: AuctionCycle,
    className?: string
}) => {
    if (auctionCycleParam === undefined) return null;
    if (auctionCycleCurrent === undefined) return null;
    return (
        <>
            <h1 className='text-nowrap text-4xl px-0 mb-0 justify-center justify-items-center flex text-center '>
                Cycle {auctionCycleParam}
            </h1>
            <Countdown showLabel />
            <div>
                <BlockHeightCountdown />
            </div>
        </ >
    )
}

function getAuctionRedirectPath(
    auctionCycleCurrent: AuctionCycle | undefined,
    currentPath: string,
    auctionCycleParam: AuctionCycle | string | undefined
): string | null {
    if (!auctionCycleCurrent) { return null }
    if (currentPath.replace(/\/$/, '').endsWith(`/auction`)) { return `/auction/${auctionCycleCurrent}` }
    if (auctionCycleParam === 'results') { return `/auction/${auctionCycleCurrent - 1}/results` }

    const parsed = z.coerce.number().int().nonnegative().safeParse(auctionCycleParam);
    if (!parsed.success) { return `/auction/${auctionCycleCurrent}` }
    if (parsed.data < auctionCycleCurrent) { return `/auction/${auctionCycleParam}/results` }
    if (parsed.data < 0 || parsed.data >= auctionCycleCurrent) {
        return `/auction/${auctionCycleCurrent}`;
    }

    return null;
}
